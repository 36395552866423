import { Epworth } from '../remote-graphql-types';
import { ChatfuelBlockIds, ChatfuelBlockNames } from '../chat/chatfuel-blocks-types';
import { IQuestionnaireResultExplanationData } from './questionnaire';

export const epworthThreshold = [5, 10, 15, 24];

export const computeEpworthPoints = (epworth: Omit<Epworth, 'user' | 'updated_at'>): number =>
  epworth.q1 + epworth.q2 + epworth.q3 + epworth.q4 + epworth.q5 + epworth.q6 + epworth.q7 + epworth.q8;

/*
 *
 * Make sure the redirectBlock and Initial Block are the same block
 *
 */
const redirectBlock: ChatfuelBlockIds = '653816ff3f01842fd4b6be0d';
export const epworthInitialBlockName: ChatfuelBlockNames = 'epworth_youpage_entrance';

export const epworthQuestionnaire = { name: 'Escala de Sonolência Epworth', redirect: redirectBlock };

export const epworthResultExplanationData: IQuestionnaireResultExplanationData = {
  title: 'Escala de Sonolência de Epworth',
  description: 'A escala tem como objetivo avaliar a sonolência diurna e a probabilidade de cochilar em diferentes situações.',
  results: [
    {
      title: '0 - 7 pontos',
      description: 'nenhuma sonolência diurna ou probabilidade de cochilar',
    },
    {
      title: '8 - 9 pontos',
      description: 'sonolência diurna leve a moderada, com risco leve de cochilar em algumas situações',
    },
    {
      title: '10 - 15 pontos',
      description: 'sonolência diurna moderada, com risco moderado de cochilar em várias situações',
    },
    {
      title: '16 - 24 pontos',
      description: 'sonolência diurna severa, com alto risco de cochilar em muitas situações',
    },
  ],
};
